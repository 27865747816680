'use client';
import { useEffect } from 'react';
import Spinner from '@/components/Spin/Spinner';

export default function GlobalError() {
  useEffect(() => {
    if (typeof window !== 'undefined') window.location.href = '/not-found';
  }, []);
  return <Spinner />;
}
