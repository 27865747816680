import React, { HTMLAttributes } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import styles from './Spinner.module.scss';

interface Props extends HTMLAttributes<HTMLDivElement> {
  style?: React.CSSProperties;
}

export default function Spinner({ style }: Props) {
  return (
    <div className={styles.container} style={style}>
      <CircularProgress size={'5rem'} />
    </div>
  );
}
